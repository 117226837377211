import { ArrowRight, Forward } from "lucide-react";
import { Button } from "../ui/button";

let startNowLink = "/products/social-media-management-course";

interface Props {
  isLoggedIn: boolean;
}

const Promo = ({ isLoggedIn }: Props) => {
  return (
    <div
      className="w-full relative h-[500px] md:h-[400px] bg-secondary bg-right bg-no-repeat bg-cover my-12 md:mt-20"
      style={{
        backgroundImage: "url(/img/home/are-you-on-social-media-100.jpg)",
      }}>
      <div className="container h-full" id="intro">
        <div className="h-full">
          <div className="pt-28 lg:pt-8 pl-6 md:pl-12 flex flex-col items-start h-full lg:justify-center space-y-3">
            <h3 className="text-xl md:text-2xl text-white">Is Your Business</h3>
            <h1 className="text-3xl md:text-5xl text-tertiary font-semibold">
              On Social Media?
            </h1>
            <div className="group">
              {isLoggedIn ? (
                <a href={startNowLink} className="block">
                  <Button
                    variant="secondary"
                    className="font-medium !rounded-full !px-10">
                    Start Now
                  </Button>
                </a>
              ) : (
                <a href={startNowLink} className="block w-full group">
                  <Button
                    variant="secondary"
                    className="font-medium !rounded-full !px-10 w-full">
                    Start Now
                    <span className="ml-1 opacity-0 group-hover:opacity-100 transition-all ease-out duration-500">
                      <ArrowRight />
                    </span>
                  </Button>
                </a>
              )}
            </div>
          </div>

          <div className="absolute right-12 -bottom-4 md:bottom-8 md:right-20 lg:right-32 lg:-bottom-10">
            <div className="motion-safe:animate-slow-bounce">
              <img
                src="/img/home/Artboard-9-8.png"
                alt="Artboard-9-8"
                className="h-full max-h-[200px] md:max-h-[300px] lg:max-h-[400px] scale-[1.5] md:scale-[1.2] w-auto rotate-12 -translate-x-10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
